import React from 'react';
import { Spinner } from 'react-bootstrap';

const ScanLoader = (props) => {
    return (
        <div className={"d-flex align-items-center"} style={props.style}>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className='me-3'
                    style={props.style}
                />
                <p className='m-0 ml-1'><strong>Waiting for code to scan...</strong></p>
        </div>
    )
}

export default ScanLoader;
