import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import sumsLogo from '../sums_logo.png';

const ScreenContainer = (props) => {
    return (
        <div>
            <Navbar bg="white">
                <Container>
                    <Navbar.Brand><img onClick={() => window.location.reload()} src={props.logo ?? sumsLogo} alt="Logo" className="logo" style={{cursor: 'pointer'}} /></Navbar.Brand>
                </Container>
            </Navbar>
            <Container className={"d-flex " + (props.noMargin ? "mt-1 " : "mt-4 ") + " flex-column" + (props.containerStyling ? " card border-0 card-body" : "")}>
                {props.title !== '' && <h3 className="mb-2">{props.title}</h3>}
                {props.children}
            </Container>
        </div>
    )
}

export default ScreenContainer;
