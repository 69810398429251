import React, { Component } from 'react'
import { Spinner, Button, Table, InputGroup, FormControl, FormText} from 'react-bootstrap';
import { ArrowClockwise, FilePerson, InputCursorText, QrCode, Search } from 'react-bootstrap-icons';

import ScreenContainer from './components/ScreenContainer';
import ScanLoader from './components/ScanLoader';
import './App.css';
import AppAlert from './components/AppAlert';

import CustomContainer from './components/CustomContainer';
import { QrScanner } from '@yudiel/react-qr-scanner';

import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/solid.css";

class App extends Component {

    constructor(props){
        super(props)

        this.textInput = React.createRef();
        this.nextButton = React.createRef();

        const urlParams = new URLSearchParams(window.location.search);
        const bearerToken = urlParams.get('token') || null;
        const siteId = urlParams.get('siteId') || null;

        this.state = {
          bearerToken: bearerToken,
          siteId: siteId,
          site: [],
          menuList: [],
          itemList: [],
          isLoading: true,
          fetchedMenuList: false,
          fetchedItems: false,
          type: null,
          itemId: null,
          openScanner: false,
          delay: 500,
          scanMessage: null,
          scanSuccess: null,
          allowScan: true,
          cameraPermission: true,
          searchTickets: false,
          tickets: [],
          search: '',
          error: false,
          listScanStatus: false,
          listScanMessage: null,
          listScanSuccess: null,
          productSearch: false,
          productSearchValue: null,
          productFeatureEvent: null,
          productFeatureTicket: null,
          productFeature: false,
          productFeatureValue: null,
          searchErrorMessage: null,
          scannedTotal: null,
          ticketTotal: null,
          previousState: null,
          showCamera: false,
          scanField: null,
          isStudentCardScan: false,
        }

        this.defaultState = this.state;

        // Fetch the menu item list
        if(this.state.bearerToken !== null && this.state.bearerToken !== null && this.state.fetchedMenuList === false)
        {
            this.fetchSite();
            this.fetchMenuList();
        }

        this.successfulScan = this.successfulScan.bind(this)
        this.errorScan = this.errorScan.bind(this)
      }
      async restart() {
        this.setState(this.defaultState)
      }
      async fetchMenuList() {
          await this.setState({
            isLoading: true,
            menuList: [],
            fetchedMenuList: false,
            fetchedItems: false,
            type: null,
            itemId: null,
            openScanner: false,
          });

          fetch(process.env.REACT_APP_PLUTO_URL + '/scanners', {
              method: 'get',
              headers: new Headers({
                  'Authorization': 'Bearer ' + this.state.bearerToken,
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
              })
          })
            .then(res => res.json())
            .then(async (result) => {
                const {menu_items} = result;

                await this.setState({
                    isLoading: false,
                    menuList: menu_items,
                    fetchedMenuList: true,
                });
            });
      }
      async productSearch(typeId) {
        await this.setState({
            type: typeId,
            productSearch: true,
        });
      }

        async fetchSite() {
            fetch(process.env.REACT_APP_PLUTO_URL + '/sites/' + this.state.siteId, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            })
            })
            .then(res => res.json())
            .then(async (result) => {
                await this.setState({
                    site: result
                });

                document.getElementById("scanner-favicon").setAttribute("href", this.state.site.icon_url)
                document.getElementById("scanner-icon").setAttribute("href", this.state.site.icon_url)

                if (this.state.site.name !== undefined) {
                    document.title += ' | ' + this.state.site.name
                }
            })
        }

      async fetchScanned() {
        if(this.state.itemId == null) return;

          fetch(process.env.REACT_APP_PLUTO_URL + '/scanners/scanned?eventDateId=' + this.state.itemId, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + this.state.bearerToken,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            })
          })
          .then(res => res.json())
          .then(async (result) => {
            await this.setState({
                scannedTotal: result.scannedTickets,
                ticketTotal: result.totalTickets
            })
          })
      }

      async fetchProducts() {
        await this.setState({
            isLoading: true,
            previousState: this.state,
        });

        fetch(process.env.REACT_APP_PLUTO_URL + '/scanners?type=' + this.state.type + '&productId=' + this.state.productSearchValue, {
          method: 'get',
          headers: new Headers({
              'Authorization': 'Bearer ' + this.state.bearerToken,
              'Content-Type': 'application/json',
              'Accept': 'application/json',
          })
      })
        .then(res => res.json())
        .then(async (result) => {
            if (!result || result?.errors) {
                await this.setState({
                    isLoading: false,
                    productSearchValue: null,
                    searchErrorMessage: 'Please provide a valid Product ID'
                });
            } else {
                await this.setState({
                    isLoading: false,
                    itemList: result.items,
                    fetchedItems: true,
                    productSearch: false,
                    productSearchValue: null,
                });
            }

        });
      }
      async fetchItems(typeId) {
          await this.setState({
              isLoading: true,
              type: typeId
          });

          fetch(process.env.REACT_APP_PLUTO_URL + '/scanners?type=' + this.state.type, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + this.state.bearerToken,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            })
        })
          .then(res => res.json())
          .then(async (result) => {
              await this.setState({
                  isLoading: false,
                  itemList: result.items,
                  fetchedItems: true,
              });
          });
      }
      async openScanner(itemId, showCamera = true) {
          await this.setState({
              itemId: itemId,
              openScanner: true,
              scanMessage: null,
              scanSuccess: null,
              allowScan: true,
              showCamera: true,
          });

          // Student scan reset
          await this.setIsStudentCardScan(false);

          this.fetchScanned(itemId);

          if (!showCamera) {
            this.toggleInput()
          }
      }
      async resetScanner() {
          await this.setState({
              openScanner: false,
              allowScan: false,
          });

          // Student scan reset
          await this.setIsStudentCardScan(false);

          await this.setState({
              openScanner: true,
              allowScan: true,
          });
          this.fetchScanned(this.state.itemId);
      }

      async setIsStudentCardScan(isScan = null) {
        if(isScan === null)
        {
            isScan = !this.state.isStudentCardScan ? true : false;
        }

        await this.setState({
            isStudentCardScan: isScan,
        });
      }

      async toggleInput() {
        await this.setState({
            showCamera: !this.state.showCamera,
        });

        if (!this.state.showCamera) {
            if(typeof this.textInput.current !== 'undefined' && this.textInput.current !== null)
            {
                this.textInput.current.focus();
            }
        }

        this.fetchScanned(this.state.itemId);
    }
      async fetchTickets(itemId = 0, searchText = null) {
        await this.setState({
            isLoading: true,
        });

        if (itemId > 0) {
            await this.setState({
                itemId: itemId,
            });
        }

        // Student card scan
        if(searchText !== null)
        {
            await this.setState({
                search: searchText
            });
        }

        let url = process.env.REACT_APP_PLUTO_URL + '/events/tickets/members?eventDateId=' + this.state.itemId;

        if (this.state.search !== "") {
            url += '&search=' + this.state.search;
        }

        fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + this.state.bearerToken,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            })
        })
          .then(res => res.json())
          .then(async (result) => {
              await this.setState({
                isLoading: false,
                searchTickets: true,
                tickets: result.data,
              });
          })
          .catch(async (error) => {
            await this.setState({
              isLoading: false,
              error: true,
            });
        });
      }
      async successfulScan(result){
        if(result && this.state.allowScan){
            await this.setState({
                allowScan: false,
                scanField: null,
            });

            // Student card scan
            if(this.state.isStudentCardScan)
            {
                // Reset for next scan
                await this.setIsStudentCardScan(false);

                // Clean scanner
                await this.setState({
                    allowScan: true,
                });

                this.fetchTickets(this.state.itemId, result);
                return;
            }

            const types = ["events", "group_events", "product_premium_upgrades"];
            let serialCode = result;

            // Append E_
            if(types.includes(this.state.type) && serialCode !== null)
            {
                if(serialCode.substring(0,2) !== 'E_' && serialCode.substring(0,2) !== 'M_')
                {
                    serialCode = 'E_' + serialCode;
                }
            }

            if(this.state.type === 'product_premium_upgrades') {
                fetch(process.env.REACT_APP_PLUTO_URL + '/scanners/scanned/premium-upgrades?' + new URLSearchParams({
                    eventTicketSerial: serialCode
                }), {
                    method: 'get',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + this.state.bearerToken,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    })
                })
                .then(res => res.json())
                .then(async(result) => {
                    if(result.success !== null && result.success !== undefined) {
                        await this.setState({
                            scanMessage: result.message,
                            scanSuccess: result.success,
                        });
                        return;
                    }

                    let response = `Event Date: ${result.event_date_name}
                    Ticket: ${result.product_name}
                    `;

                    await this.setState({
                        scanSuccess: true,
                        scanMessage: response,
                        productFeatureEvent: result.event_date_name,
                        productFeatureTicket: result.product_name,
                        productFeatureValue: result.product_premium_upgrade
                    });
              })

                return;
            }

            fetch(process.env.REACT_APP_PLUTO_URL + '/scanners', {
                method: 'post',
                body: JSON.stringify({
                    type: this.state.type,
                    id: this.state.itemId,
                    serial: serialCode,
                }),
                headers: new Headers({
                    'Authorization': 'Bearer ' + this.state.bearerToken,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                })
            })
              .then(res => res.json())
              .then(async (result) => {
                  await this.setState({
                      scanMessage: result.message,
                      scanSuccess: result.success,
                  });

                  if(typeof this.textInput.current !== 'undefined' && this.textInput.current !== null)
                  {
                    this.textInput.current.value = '';
                  }

                  this.nextButton.current.focus();
              });
        }
      }

      async scanFromList(serial){
        fetch(process.env.REACT_APP_PLUTO_URL + '/scanners', {
            method: 'post',
            body: JSON.stringify({
                type: this.state.type,
                id: this.state.itemId,
                serial: serial,
            }),
            headers: new Headers({
                'Authorization': 'Bearer ' + this.state.bearerToken,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            })
        })
          .then(res => res.json())
          .then(async (result) => {
              await this.setState({
                  listScanStatus: true,
                  listScanMessage: result.message,
                  listScanSuccess: result.success,
              });
          });
      }

      async errorScan(err){
        await this.setState({
            cameraPermission: false,
        });
      }

      async nextScan() {
          await this.setState({
              scanMessage: null,
              scanSuccess: null,
              allowScan: true,
              productFeatureValue: null,
              productFeatureEvent: null,
              productFeatureTicket: null
          })

        if(typeof this.textInput.current !== 'undefined' && this.textInput.current !== null)
        {
            this.textInput.current.focus();
        }
        this.fetchScanned(this.state.itemId);
      }

      async nextListScan() {
        await this.setState({
            listScanMessage: null,
            listScanSuccess: null,
            listScanStatus: false,
        })

        this.fetchTickets();
      }

      async backToCamera() {
        await this.setState({
            searchTickets: false,
            tickets: [],
            search: '',
        });
      }

      async resetState() {
        if (this.state.previousState !== null) {
            this.setState(this.state.previousState)
        }
      }

      async productFeature(typeId) {
        await this.setState({
            type: typeId,
            productFeature: true,
        });
      }

      async determineAction(menuItemId) {
        if(menuItemId === 'product_collection') {
            this.productSearch(menuItemId);
            return;
        }

        if(menuItemId == 'product_premium_upgrades') {
            this.productFeature(menuItemId);
            return;
        }

        this.fetchItems(menuItemId);
      }

    onScanSubmit = e => {
        e.preventDefault();

        this.successfulScan(this.state.scanField)
        // send state to server with e.g. `window.fetch`
    }

    typeTitle(item) {
        switch (item) {
            case 'events':
                return "Events"
            case 'group_events':
                return "Group Events"
            case 'product_collection':
                return "Product Collection"
            case 'group_interest':
                return "Register Interest"
            case 'product_premium_upgrades':
                return 'Premium Upgrades';
            default:
                 return '';
        }
    }

    helpText(item) {
        switch (item) {
            case 'events':
                return "Search and scan tickets to register attendance for events within your union"
            case 'group_events':
                return "Register attendance for events organised by groups you're a part of"
            case 'product_collection':
                return "Scan to find and mark shop products as collected"
            case 'group_interest':
                return "Scan Member Passes to register interests to groups you're a part of"
            case 'product_premium_upgrades':
                return 'Check what additional benefits a scanned ticket is entitled to';
            default:
                 return ''
        }
    }
      render(){
        const textColor = {
            color: this.state.site.primary_colour ?? '#1ab394',
        }

        const sumsColor = {
            backgroundColor: this.state.site.primary_colour ?? '#1ab394',
            borderColor: this.state.site.primary_colour ?? '#1ab394',
        }

        const backButton = {
            backgroundColor: 'transparent',
            color: this.state.site.primary_colour ?? '#1ab394',
            borderColor: this.state.site.primary_colour ?? '#1ab394',
            marginBottom: '10px'
        }

        const visibleScannerStyle = {
            width: '100vw',
            visibility: 'visible'
        }

        const actionButton = {
            color: this.state.site.primary_colour ?? '#1ab394',
            padding: '13px'
        }

        const button = {
            color: '#fff',
            backgroundColor: this.state.site.primary_colour ?? '#1ab394',
            borderColor: this.state.site.primary_colour ?? '#1ab394',
            marginRight: '10px',
            marginBottom: '10px'
        }

        const isStudentCardButtonNonActive = {
            color: '#fff',
            backgroundColor: this.state.site.primary_colour ?? '#1ab394',
            borderColor: this.state.site.primary_colour ?? '#1ab394',
            marginRight: '10px',
            marginBottom: '10px',
            opacity: 0.7,
        }

        const isStudentCardButtonActive = {
            color: '#fff',
            backgroundColor: this.state.site.primary_colour ?? '#1ab394',
            borderColor: this.state.site.primary_colour ?? '#1ab394',
            marginRight: '10px',
            marginBottom: '10px'
        }

        const qrScannerContainerStyle = {
            borderRadius: '15px'
        };

        // No site and styling set
        if(this.state.site.length === 0)
        {
            return (
                <div className="complete-center d-flex flex-column align-items-center justify-content-center">
                    <Spinner className="bg-light" animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            );
        }

        // No bearer token is set
        if(this.state.bearerToken === null)
        {
            return (
            <ScreenContainer logo={this.state.site.logo_url} title="Oops...">
                <AppAlert site={this.state.site} title="Unauthorised Request" error="Head over to the SU App to access the QR scanner. If you're having issues, please contact your Union." />
            </ScreenContainer>
            );
        }

        // Unknown error occurred
        if(this.state.error === true)
        {
            return (
                <ScreenContainer logo={this.state.site.logo_url} title="Oops...">
                    <AppAlert site={this.state.site} title="Unknown error" error="An error occurred. If this keeps happening, contact your Union for help" />
                </ScreenContainer>
            );
        }

        // Is Loading
        if(this.state.isLoading)
        {
            return (
                <ScreenContainer logo={this.state.site.logo_url}>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <Spinner style={textColor} animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                </ScreenContainer>
            );
        }

        // Show product search screen if product_collection type
        if(this.state.productSearch === true)
        {
            return (
                <ScreenContainer title="Search..." logo={this.state.site.logo_url}>
                    <div className="mt-2">
                        <CustomContainer>
                        <p>{this.helpText('product_collection')}</p>
                        <InputGroup className="mb-3 mt-2">
                            <FormControl
                                placeholder="Enter a product Identifier..."
                                onChange={event => this.setState({
                                    productSearchValue: event.target.value
                                })}
                            />
                            <Button style={sumsColor} onClick={() => this.fetchProducts()}>
                                Search
                            </Button>
                        </InputGroup>
                        {this.state.searchErrorMessage !== null &&
                        <FormText className="text-danger">
                            {this.state.searchErrorMessage}
                        </FormText>}
                        </CustomContainer>
                    </div>
                </ScreenContainer>
            );
        }

        if(this.state.productFeature === true)
        {
            return (
                <ScreenContainer logo={this.state.site.logo_url}>
                    <div className="row justify- content-center">
                        <div className="col-sm-12 col-md-6 mb-3">
                            <QrScanner
                                containerStyle={qrScannerContainerStyle}
                                videoStyle={!this.state.allowScan && {opacity: "15%"}}
                                scanDelay={this.state.delay}
                                onError={this.errorScan}
                                onDecode={this.successfulScan}
                            />
                        </div>

                        <div className='col-sm-12 col-md-6'>
                            <div className={"d-flex justify-content-center flex-column"}>
                                <div>
                                    <h3 className="mb-2 toggle-large">{this.typeTitle(this.state.type)}</h3>
                                </div>

                                <CustomContainer>
                                    <div>
                                        {this.state.scanSuccess === null ?
                                            <ScanLoader style={textColor}/>
                                            :
                                            this.state.scanSuccess ?
                                            <>
                                                <div className='mb-4'>
                                                    <h4 className='mb-0'>{this.state.productFeatureEvent}</h4>
                                                    <p>Ticket: {this.state.productFeatureTicket}</p>
                                                </div>

                                                <div className='mb-4'>
                                                    <h5 className='mb-2'>Premium Upgrades:</h5>

                                                    {this.state.productFeatureValue !== null && this.state.productFeatureValue.length > 0 ?
                                                        <ul className='no-bullets'>
                                                            {this.state.productFeatureValue.map((feature, i) => (
                                                                <li key={i} className='position-relative'>
                                                                    <i className={'position-absolute fa ' + feature.icon} style={{top: '4.5px', left: '-24px'}}></i>
                                                                    <p className='mb-0'>{feature.name}</p>
                                                                    <p style={{fontSize: '14px'}}>{feature.description}</p>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        :
                                                        <p>No Premium Upgrades!</p>
                                                    }
                                                </div>

                                                <Button style={sumsColor} autoFocus={true} ref={this.nextButton} onClick={() => this.nextScan()}>Next Scan</Button>
                                            </>
                                            :
                                            <div className="overlay">
                                                <div className="d-flex complete-center flex-column justify-content-center align-items-center">
                                                    <p className={this.state.scanSuccess ? 'text-white' : 'text-danger'}>{this.state.scanMessage}</p>
                                                    <Button style={sumsColor} autoFocus={true} ref={this.nextButton} onClick={() => this.nextScan()}>Next Scan</Button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </CustomContainer>
                            </div>

                            <div className="d-flex justify-content-center flex-column mt-3">
                                <CustomContainer>
                                    <Button role="button" style={button} onClick={() => this.resetScanner()}><ArrowClockwise size={30} /></Button>
                                </CustomContainer>
                            </div>
                        </div>
                    </div>
                </ScreenContainer>
            )
        }

        if(this.state.searchTickets === true)
        {
            return (
                <ScreenContainer logo={this.state.site.logo_url} title="Search tickets...">
                    {this.state.listScanStatus && this.state.listScanMessage && <div className="overlay">
                        <div className="d-flex complete-center flex-column justify-content-center align-items-center">
                            <p className={this.state.listScanSuccess ? 'sums-color' : 'text-danger'}>{this.state.listScanSuccess ? 'Success! Ticket has been admitted': this.state.listScanMessage}</p>
                            <Button style={sumsColor} onClick={() => this.nextListScan()}>Go back</Button>
                        </div>
                    </div>}
                    <Button style={backButton} className="mb-10 mt-2 btn-sm" onClick={() => this.backToCamera()}>
                        Go Back
                    </Button>
                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder="Search for Customer Name, Student ID, Ticket ID or Transaction ID"
                            onChange={event => this.setState({
                                search: event.target.value
                            })}
                        />
                        <Button style={sumsColor} onClick={() => this.fetchTickets()}>
                            Search
                        </Button>
                    </InputGroup>
                    {this.state.tickets === undefined ? <AppAlert site={this.state.site} title="No Results" error="There are no tickets for this event" /> :
                    <Table striped bordered responsive hover>
                        <thead>
                            <tr>
                            <th>Ticket ID</th>
                            <th>Name</th>
                            <th>Student ID</th>
                            <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.tickets?.length > 0 && this.state.tickets.map((ticket) => {
                                return (
                                    <tr>
                                        <td>{ticket.id}</td>
                                        <td>{ticket.member.name}</td>
                                        <td>{ticket.member.isStudent ? ticket.member.student_id : 'N/A'}</td>
                                        <td><Button disabled={ticket.entered_at !== null || ticket.revoked} size="sm" style={sumsColor} onClick={() => this.scanFromList(ticket.serial)}>{ticket.entered_at === null ? 'Admit' : 'Entered: ' + ticket.entered_at}</Button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>}

                </ScreenContainer>
            );
        }

        // No camera permissions set
        if(this.state.cameraPermission === false && this.state.showCamera === true)
        {
            return (
                <ScreenContainer logo={this.state.site.logo_url} title={this.typeTitle(this.state.type)}>
                    <AppAlert site={this.state.site} title="Camera permission" error="Please grant your browser access to the camera to start scanning. Alternatively, switch to text input below.">
                    <div className="mt-4">
                        <Button role="button" style={button} onClick={() => this.toggleInput()}><InputCursorText size={20} /> <span className="pl-2">Text Input</span></Button>
                        {this.state.type !== 'group_interest' && this.state.type !== 'product_collection' &&
                            <>
                                <Button role="button" style={button} onClick={() => this.fetchTickets()}><Search size={20} /> <span className="pl-2">Search Tickets</span></Button>
                            </>
                        }
                    </div>
                    </AppAlert>
                </ScreenContainer>
            );
        }

        // Scanner
        if(this.state.openScanner)
        {
            return(
                <ScreenContainer logo={this.state.site.logo_url} title={!this.state.showCamera ? this.typeTitle(this.state.type) : ''}>
                    {!this.state.allowScan && <div className="overlay">
                        <div className="d-flex complete-center flex-column justify-content-center align-items-center">
                            <p className={this.state.scanSuccess ? 'text-white' : 'text-danger'}>{this.state.scanMessage}</p>
                            <Button style={sumsColor} autoFocus={true} ref={this.nextButton} onClick={() => this.nextScan()}>Next Scan</Button>
                        </div>
                    </div>}
                    <div className="row justify-content-center">
                        {this.state.showCamera ? <div className="col-sm-12 col-md-6 mb-3">
                            <QrScanner
                                containerStyle={qrScannerContainerStyle}
                                scanDelay={this.state.delay}
                                onError={this.errorScan}
                                onDecode={this.successfulScan}
                            />
                            </div>
                        :
                        <div className="col-12 mt-2">
                            <CustomContainer>
                                <form onSubmit={e => this.onScanSubmit(e)}>
                                    <FormControl
                                        placeholder="Scan a code..."
                                        onChange={event => this.setState({scanField: event.target.value})}
                                        autoFocus={true}
                                        ref={this.textInput}
                                    />
                                </form>
                            </CustomContainer>
                        </div>
                        }
                        <div className={this.state.showCamera ? 'col-sm-12 col-md-6' : 'col-12'}>
                            <div className={"d-flex justify-content-center " + (this.state.showCamera ? "" : "mt-3") + " flex-column"}>
                                <div>
                                    <h3 className="mb-2 toggle-large">{this.typeTitle(this.state.type)}</h3>
                                </div>
                                <CustomContainer>
                                    {this.state.allowScan &&
                                        <div className="mt-1 mb-3">
                                            <ScanLoader style={textColor}/>
                                        </div>
                                    }
                                    {this.state.type === 'events' && this.state.scannedTotal !== null && this.state.ticketTotal !== null && (
                                        <>
                                            {this.state.scannedTotal !== null && <p className="mb-1"><strong>{this.state.scannedTotal} Attended</strong></p>}
                                            {this.state.ticketTotal !== null && <p><strong>{this.state.ticketTotal} Tickets Purchased</strong></p>}
                                        </>
                                    )}
                                </CustomContainer>
                            </div>
                            <div className="d-flex justify-content-center flex-column mt-3">
                                <CustomContainer>
                                    <div className="mb-3">
                                        <Button role="button" style={button} onClick={() => this.resetScanner()}><ArrowClockwise size={30} /></Button>
                                        {(this.state.type === 'events' || this.state.type === 'group_events') && <Button role="button" style={this.state.isStudentCardScan ? isStudentCardButtonActive : isStudentCardButtonNonActive} onClick={() => this.setIsStudentCardScan()}><FilePerson size={30} /></Button>}
                                        <Button role="button" style={button} onClick={() => this.toggleInput()}>{this.state.showCamera ? <><InputCursorText size={30} /> <span className="pl-2">Text Input</span></> : <><QrCode size={30} /> <span className="pl-2">Scanner</span></>}</Button>
                                        {this.state.type !== 'group_interest' && this.state.type !== 'product_collection' &&
                                            <Button role="button" style={button} onClick={() => this.fetchTickets()}><Search size={30} /> <span className="pl-2">Search Tickets</span></Button>
                                        }
                                    </div>
                                </CustomContainer>
                            </div>
                        </div>
                    </div>
                </ScreenContainer>
              )
        }

        // Item list
        if(this.state.fetchedItems)
        {
            return (
                <>
                    <ScreenContainer title={this.state.itemList.length > 0 ? 'Select one...' : this.state.itemList.length === 0 ? this.typeTitle(this.state.type) : ''} logo={this.state.site.logo_url}>
                        {this.state.itemList.length === 0 &&
                            <>
                                {this.state.previousState !== null && <button style={backButton} className="btn btn-sm" onClick={() => this.resetState()}>Go Back</button>}
                                <AppAlert site={this.state.site} title="No Results" error="There is currently nothing available to scan" action={this.resetState} />
                            </>
                        }
                        <div className="mt-2">
                            {this.state.itemList.length > 0 && this.state.itemList.map((listItem) => {
                                let startDate = '';
                                let endDate = '';

                                if (listItem.start_date !== undefined && listItem.end_date !== undefined) {
                                    startDate = new Date(listItem.start_date);
                                    endDate = new Date(listItem.end_date);

                                    if (startDate.toLocaleString([], {dateStyle: 'short'}) === endDate.toLocaleString([], {dateStyle: 'short'})) {
                                        endDate = endDate.toLocaleString([], {timeStyle: 'short'})
                                    } else {
                                        endDate = endDate.toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})
                                    }

                                    startDate = startDate.toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})
                                }

                                return (
                                    <div className="w-100 pb-3">
                                        <CustomContainer>
                                            <h5 style={textColor}>{listItem.title}</h5>
                                            {startDate !== '' && endDate !== '' && <p className="d-flex flex-wrap text-body mb-3">{startDate} - {endDate}</p>}
                                            <div className="mt-3">
                                                <Button role="button" style={button} onClick={() => this.openScanner(listItem.id, false)}><InputCursorText size={20} /> <span className="pl-2">Text Input</span></Button>
                                                <Button role="button" style={button} onClick={() => this.openScanner(listItem.id)}><QrCode size={20} /> <span className="pl-2">Scanner</span></Button>
                                                {this.state.type !== 'group_interest' && this.state.type !== 'product_collection' &&
                                                    <Button role="button" style={button} onClick={() => this.fetchTickets(listItem.id)}><Search size={20} /> <span className="pl-2">Search Tickets</span></Button>
                                                }
                                            </div>
                                        </CustomContainer>
                                    </div>
                                )
                            })}
                        </div>
                    </ScreenContainer>
                </>
            );
        }

        // Menu list
        if(this.state.fetchedMenuList)
        {
        const anythingToScan = (this.state.menuList === undefined || this.state.menuList?.length === 0);

        return (
            <ScreenContainer title={!anythingToScan ? 'I would like to scan...' : ''} logo={this.state.site.logo_url}>
                {anythingToScan && (
                    <AppAlert site={this.state.site} title="No Results" error="There is currently nothing available to scan" helpText="Either your link has expired or your account doesn't have the correct permissions" />
                )}
                <>
                    <div className="d-flex flex-wrap align-items-center justify-content-center mt-3">
                        {this.state.menuList?.length > 0 && this.state.menuList.map((menuItem, index) => {
                            return (
                                <a key={index} href="#" role="button" className="w-100 mb-3" onClick={() => this.determineAction(menuItem.id)}>
                                    <CustomContainer>
                                        <h5 style={textColor}>{menuItem.name}</h5>
                                        <p className="d-flex flex-wrap text-body mb-0">{this.helpText(menuItem.id)}</p>
                                    </CustomContainer>
                                </a>
                            )
                        })}
                    </div>
                </>
            </ScreenContainer>
        );
        }
      }
    }

export default App;
