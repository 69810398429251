import React from 'react';

const CustomContainer = (props) => {
    return (
        <div {...props}>
            <div className="card border-0">
                <div className="card-body">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default CustomContainer;
